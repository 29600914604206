<template>
  <SiteMain>
    <GridSection blockHeading filterPanel colPadding marginBottom :heading="pageTitle">
      <template slot="filters">
        <SortBy v-model="orderBy">
          <option value="course-desc" selected>{{ $t('Date newest first') }}</option>
          <option value="course-asc">{{ $t('Date oldest first') }}</option>
        </SortBy>
        <FilterBar v-model="selectedPhase">
          <option value="" selected>{{ $t('Show all') }}</option>
          <option :value="phase.slug" v-for="(phase, i) in phases" :key="i">
            {{ phase.title }}
          </option>
        </FilterBar>
      </template>
      <Card
        class="col-md-6 col-lg-4 col-p"
        v-for="(post, i) in filteredPosts"
        :key="i"
        :title="post.title"
        :image="post.image"
        :link="post.slug"
        :duration="post.view_time"
        :phase="post.phase_title"/>
    </GridSection>
  </SiteMain>
</template>

<script>
import SortBy from "@/components/SortBy.vue";
import FilterBar from "@/components/FilterBar.vue";
import SiteMain from "@/components/SiteMain.vue";
import GridSection from "@/components/GridSection.vue";
import Card from "@/components/Card.vue";

export default {
  components: {
    SortBy,
    FilterBar,
    SiteMain,
    GridSection,
    Card,
  },
  data() {
    return {
      pageTitle: "Video's",
      orderBy: "course-desc",
      selectedPhase: "",
      errors: [],
    };
  },
  computed: {
    phases() {
      return this.$store.state.cache.data[`content${this.$i18n.locale}`]?.phases ?? [];
    },
    posts() {
      return this.$store.state.cache.data[`content${this.$i18n.locale}`]?.contents ?? [];
    },
    filteredPosts: function () {
      let posts = this.posts;
      if (this.orderBy === "course-desc") {
        posts = posts.sort((a, b) => (a.publication_date > b.publication_date ? -1 : 1));
      } else if (this.orderBy === "course-asc") {
        posts = posts.sort((a, b) => (a.publication_date > b.publication_date ? 1 : -1));
      }

      if (this.selectedPhase === "") {
        return posts;
      }
      return posts.filter((post) => post.phase_slug === this.selectedPhase);
    },
  },
  methods: {
    async loadPage() {
      const key = `content${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/content/all?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadPage();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPage();
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 16px;
}
</style>
