<template>
  <div class="filter-bar">
    <span>Filter</span>
    <div class="form-select--wrap">
      <select name="filter" :value="value" @input="onInput">
        <slot />
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 25px;
  span {
    display: inline-block;
    margin-right: 15px;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    line-height: 24px;
    @include tablet-down {
      display: none;
    }
  }
  .form-select--wrap {
    position: relative;
    max-width: 100%;
    width: 8.5rem;
    border: 1px solid $white;
    overflow: hidden;
    select {
      width: 100%;
      font-size: 1rem;
      font-weight: 500;
      color: white;
      line-height: 24px;
      padding: 0.5em;
      border: none;
      cursor: pointer;
      @include appearance();
      background: none;
      background: url("~@/assets/img/icons/dropdown.svg") no-repeat;
      background-size: auto 6px;
      background-position: right 10px center;
      option {
        color: $primary;
      }
    }
  }
}
</style>